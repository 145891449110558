<template>
  <div>
  <v-data-table
    :headers="headers"
    :items="trxchecks"
    sort-by="voucher_no"
    class="elevation-1"
     dense
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Disbursement Entry</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field> 
        <v-divider
          class="mx-4"
          inset
          vertical
        ></v-divider>
        <v-spacer></v-spacer>
        
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
      <v-icon
        color="primary"
        class="mr-2"
        small
        @click="editItem(item)"
      >
        edit
      </v-icon>
      <v-icon
        small
        color="red"
        @click="deleteItem(item)"
      >
        delete
      </v-icon>
    </template>
    
  </v-data-table>
  </div>
</template>

<script>
// import createTrxcheck from './createTrxcheck.vue';
import { mapGetters } from 'vuex';

export default {
	// components: {
	// 	'create-trxcheck': createTrxcheck
	// },
	data(){
		return{
			search: ''
		};
	},
	computed: {
		...mapGetters({
			trxchecks: 'trxcheck/trxchecks',
			headers: 'trxcheck/headers',
			editedItem: 'trxcheck/editedItem',
			defaultItem: 'trxcheck/defaultItem',
			dialog: 'trxcheck/dialog',
			editedIndex: 'trxcheck/editedIndex',
			valid: 'trxcheck/valid'
		}),
	},
	mounted () {
		this.$store.dispatch('trxcheck/getTrxchecks');
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('trxcheck/setEditedIndex',this.trxdisbs.indexOf(item));
			this.$store.dispatch('trxcheck/setEditedItems',Object.assign({}, item));
			this.$store.dispatch('trxcheck/setDialog',true);
			this.$store.dispatch('trxcheck/setValid',true);
			this.$store.dispatch('trxcheck/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete!',
				text: item.voucher_no + '(' + item.voucher_date + ')' + '? You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('trxcheck/setisLoading',true);
					this.$store.dispatch('trxcheck/deleteTrxcheck',item);
				}
			});
		}
	}
};
</script>
