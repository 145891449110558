<template>
    <div class="home">
        <table-trxcheck></table-trxcheck>
  </div>
</template>

<script>
import tableTrxcheck from '../../components/pages/trx/trxcheck/tableTrxcheck.vue';

export default {
	components: {
		'table-trxcheck': tableTrxcheck
	}
};
</script>

<style>

</style>

